import * as React from "react"
import { graphql} from 'gatsby'
import { StructuredText } from 'react-datocms';
import BlurredImageCaption from '../components/blurred-image-caption';

import Layout from "../components/layout";
import { HelmetDatoCms } from 'gatsby-source-datocms'

import { Helmet } from "react-helmet";

const Page = ({data}) => {
    return (
    <Layout>
        <Helmet
        bodyAttributes={{
            class: 'secondary-page'
        }}
        >
            <meta name="version" content="1.0.667" />
        </Helmet>
        {data.page.nodes.map(page => (
            <main key={page.id} className="secondary-page">
                <HelmetDatoCms 
                    seo={page.seoMetaTags}
                />
                <h1>{page.pageTitle}</h1>
                <div className="secondary-page-content">
                    <StructuredText 
                        data={page.content}
                        renderBlock={({ record }) => {
                        if (record.__typename === "DatoCmsBlurredImage") {
                            return <BlurredImageCaption image={record.image} />;
                        }
                        return (
                            <p>Woops! Something went wrong...</p>
                        );
                        }}
                    />
                </div>
            </main>
        ))}
    </Layout>
    )
}
export default Page

export const query = graphql`
    query PageQuery(
    $language: String!,
    $id: String!
    ) {
    page: allDatoCmsPage(filter: {originalId: {eq: $id}, locale: {eq: $language}}) {
        nodes {
            originalId
            id
            pageTitle
            content {
                value
            }
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
    }
}
`
