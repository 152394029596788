import * as React from "react";
import BlurredImage from "./blurred-image";
import ChildLink from "./child-link";

const BlurredImageCaption = ({ image, url, language }) => {
  const imageBgStyle = {
    backgroundImage: "url(" + image.url + ")",
  };
  if (url !== "") {
    return (
      <ChildLink href={url} language={language} classes="">
        <BlurredImage image={image} />
      </ChildLink>
    );
  } else {
    return <BlurredImage image={image} />;
  }
};
export default BlurredImageCaption;
